<!--
 * @Author: your name
 * @Date: 2021-09-15 14:28:14
 * @LastEditTime: 2021-09-16 11:58:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\whole-process-web\src\views\main\basicPage\quality\stateDialog.vue
-->
<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      title="整改状态"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="handleClose"
    >
      <el-form ref="queryFormRef" :model="queryForm" :rules="rules" label-width="auto">
        <el-form-item label="整改状态" prop="rectificationState">
          <el-select v-model="queryForm.rectificationState" placeholder="请选择">
            <el-option
              v-for="item in rectificationList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            class="line"
            placeholder="请输入备注"
            v-model.trim="queryForm.remark"
            type="textarea"
            :rows="5"
            resize="none"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="tableClass">
        <span>整改记录</span>
        <el-table :data="tableData" stripe size="medium" style="width: 100%; margin-top: 26px">
          <el-table-column prop="rectificationState" label="整改情况" align="center" width="180">
            <template slot-scope="{ row }">
              <div class="stateBtn" @click="openStateDialog(row)">
                <span v-if="row.rectificationState === 1" style="color:#2FBF3F">已整改</span>
                <span v-else-if="row.rectificationState === 2" style="color:#F79818">待整改</span>
                <span v-else-if="row.rectificationState === 3" style="color:#979FA8">取消</span>
                <span v-else style="color:#F56C6C">超时</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="操作时间" align="center" width="180"></el-table-column>
          <el-table-column prop="createUserName" label="操作人" align="center" width="180">
            <template slot-scope="{ row }">{{ row.createUserName || '无' }}</template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center" show-overflow-tooltip>
            <template slot-scope="{ row }">{{ row.remark || '无' }}</template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page">
        <el-pagination
          :total="total"
          :current-page="queryForm.pageNo"
          :page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 30, 50]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
      <span slot="footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="handleSubmit">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getRectificationList, postAddSave } from 'api/main/qualityContent';

export default {
  data() {
    return {
      total: 0,
      dialogVisible: false,
      submitLoading: false,
      tableData: [],
      queryForm: {
        rectificationState: '',
        remark: '',
        qualitySafetyId: '',
        type: '',
        pageNo: 1,
        pageSize: 10
      },
      rectificationList: [
        { id: '1', value: '已整改' },
        { id: '2', value: '待整改' },
        { id: '3', value: '取消' }
      ],
      rules: {
        rectificationState: [{ required: true, message: '请选择' }, { trigger: 'blur' }]
      }
    };
  },
  methods: {
    init({ type, id }) {
      this.dialogVisible = true;
      console.log(type);
      this.queryForm.type = type;
      this.queryForm.qualitySafetyId = id;
      this.getRectificationList();
    },
    getRectificationList() {
      getRectificationList(this.queryForm).then(res => {
        if (res.code === 200) {
          console.log(res);
          const { total, records } = res.data;
          this.total = total;
          this.tableData = records;
        }
      });
    },
    // 分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNo = 1;
      this.getRectificationList();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      this.getRectificationList();
    },
    handleClose() {
      this.queryForm = this.$options.data().queryForm;
      this.$refs.queryFormRef.resetFields();
      this.dialogVisible = false;
    },
    handleSubmit() {
      this.$refs.queryFormRef.validate(valid => {
        if (!valid) return;
        this.submitLoading = true;
        postAddSave(this.queryForm)
          .then(res => {
            this.$message.success('编辑成功！');
            this.queryForm = this.$options.data().queryForm;
            this.dialogVisible = false;
            this.$emit('refresh');
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.tableClass {
  display: flex;
  span {
    margin: 40px 15px 0 10px;
    color: #303133;
    font-size: 14px;
  }
}
.page {
  margin: 16px 0;
  float: right;
}
/deep/ .el-dialog__footer {
  margin-top: 20px;
}
</style>
