/*
 * @Author: your name
 * @Date: 2021-08-30 11:34:01
 * @LastEditTime: 2021-09-15 15:16:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\api\main\qualityContent\index.js
 */
import { axios } from '@/utils/request';
/**
 * 项目列表
 */
export function getList(parameter) {
  return axios({
    url: '/typeBase/page',
    method: 'get',
    params: parameter
  });
}
export function postAdd(parameter) {
  return axios({
    url: '/typeBase/addOrEdit',
    method: 'post',
    data: parameter
  });
}
export function postDelete(parameter) {
  return axios({
    url: '/typeBase/deleteNameType',
    method: 'post',
    data: parameter
  });
}
/*项目质量管理新增 */
export function addOrEdit(parameter) {
  return axios({
    url: '/qualitySafety/addOrEdit',
    method: 'post',
    data: parameter
  });
}

/*项目质量列表 */
export function getSafetyList(parameter) {
  return axios({
    url: '/qualitySafety/list',
    method: 'get',
    params: parameter
  });
}
export function getDetail(parameter) {
  return axios({
    url: '/qualitySafety/detail',
    method: 'get',
    params: parameter
  });
}
export function postQualitySafetyDelete(parameter) {
  return axios({
    url: '/qualitySafety/delete',
    method: 'post',
    data: parameter
  });
}
/* 导入 */
export function importData(parameter) {
  return axios({
    url: '/qualitySafety/importData',
    method: 'post',
    data: parameter
  });
}
/* 整改状态列表 */
export function getRectificationList(parameter) {
  return axios({
    url: '/qualitySafetyRectification/list',
    method: 'get',
    params: parameter
  });
}
/* 整改状态列表 */
export function postAddSave(parameter) {
  return axios({
    url: '/qualitySafetyRectification/addSave',
    method: 'post',
    data: parameter
  });
}
