<template>
  <div>
    <div class="top-title">
      <div class="title">{{ titleName }}</div>
      <el-button plain @click="handleBack(false)">返回</el-button>
    </div>
    <unfoldAndStow name="项目信息">
      <el-form class="pro-form" ref="form" :model="form" :rules="rules" label-width="150px">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="巡检编号">
              <el-input v-model="form.inspectionNo" disabled placeholder="保存后自动生成"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="manageId" label="被检查管理单位">
              <el-select
                @change="changeManageName"
                v-model="form.manageId"
                :disabled="isShowDetail"
                filterable
                placeholder="请选择(可搜索)"
                style="width: 100%;"
              >
                <el-option
                  style="width: 382px;"
                  :label="item.name"
                  :value="item.id"
                  v-for="item in manageNameList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="supervisorId" label="被检查监理单位">
              <el-select
                @change="changeSupervisorName"
                v-model="form.supervisorId"
                :disabled="isShowDetail"
                filterable
                placeholder="请选择(可搜索)"
                style="width: 100%;"
              >
                <el-option
                  style="width: 382px;"
                  :label="item.name"
                  :value="item.id"
                  v-for="item in supervisorNameList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="constructionId" label="被检查施工单位">
              <el-select
                @change="changeConstructionName"
                v-model="form.constructionId"
                :disabled="isShowDetail"
                filterable
                placeholder="请选择(可搜索)"
                style="width: 100%;"
              >
                <el-option
                  style="width: 382px;"
                  :label="item.name"
                  :value="item.id"
                  v-for="item in constructionNameList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="inspectionTime" label="检查时间">
              <el-date-picker
                v-model="form.inspectionTime"
                type="date"
                @change="inspectionTimeFunc"
                value-format="yyyy-MM-dd"
                :disabled="isShowDetail"
                placeholder="请选择"
                style="width: 100%;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="rectificationTime" label="整改时间">
              <el-date-picker
                v-model="form.rectificationTime"
                type="date"
                @change="rectificationTimeFunc"
                value-format="yyyy-MM-dd"
                :disabled="isShowDetail"
                placeholder="请选择"
                style="width: 100%;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="inspectionUnit" label="检查单位">
              <el-input v-model.trim="form.inspectionUnit" :disabled="isShowDetail" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="inspectionPersonnel" label="参加检查人员">
              <el-input v-model.trim="form.inspectionPersonnel" :disabled="isShowDetail"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
            <el-form-item prop="contentIds" label="检查内容">
              <div class="content-checkbox">
                <el-checkbox-group v-model="form.contentIds">
                  <el-checkbox :disabled="isShowDetail" :label="item.id" v-for="item in contentList" :key="item.id">{{
                    item.name
                  }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
            <el-form-item label="其他检查内容">
              <el-input
                v-model.trim="form.otherInspectionContent"
                type="textarea"
                maxlength="500"
                :rows="5"
                resize="none"
                :disabled="isShowDetail"
                show-word-limit
                :placeholder="isShowDetail ? '无' : '请输入'"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
            <el-form-item label="检查发现问题">
              <el-input
                v-model.trim="form.inspectionProblem"
                type="textarea"
                maxlength="1000"
                :disabled="isShowDetail"
                :rows="5"
                resize="none"
                show-word-limit
                :placeholder="isShowDetail ? '无' : '请输入'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
            <el-form-item label="整改的意见和建议">
              <el-input
                v-model.trim="form.opinionOrProposal"
                type="textarea"
                maxlength="1000"
                :rows="5"
                resize="none"
                :disabled="isShowDetail"
                show-word-limit
                :placeholder="isShowDetail ? '无' : '请输入'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
            <el-form-item label="备注">
              <el-input
                v-model.trim="form.remark"
                type="textarea"
                maxlength="500"
                :disabled="isShowDetail"
                :rows="5"
                resize="none"
                show-word-limit
                :placeholder="isShowDetail ? '无' : '请输入'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </unfoldAndStow>
    <unfoldAndStow name="附件">
      <div style="margin: 16px 40px 20px;">
        <Upload
          :tableFileList="tableFileList"
          :fileIdList="fileIdList"
          :disabled="isShowDetail"
          @get-file-data="getFileData"
        />
      </div>
    </unfoldAndStow>
    <div class="submit-btn">
      <el-button style="margin-right: 16px;" @click="handleBack(false)">取消</el-button>
      <el-button v-if="this.editType !== 2" type="primary" @click="handleSubmit">保存</el-button>
    </div>
  </div>
</template>

<script>
import unfoldAndStow from '@/views/main/basicPage/_components/unfoldAndStow';
import Upload from '@/components/upload/UploadFile';
import { addOrEdit } from 'api/main/qualityContent';
import { getBaseList, getAllRelatedName } from 'api/common/common';
export default {
  name: 'addForm',
  components: {
    unfoldAndStow,
    Upload
  },
  props: {
    type: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      form: {
        name: '',
        contentIds: [],
        manageName: '',
        supervisorName: '',
        constructionName: '',
        projectId: '',
        otherInspectionContent: '',
        inspectionTime: '',
        rectificationTime: '',
        inspectionProblem: '',
        opinionOrProposal: '',
        remark: ''
      },
      tableFileList: [], // 回显上传附件
      fileIdList: [], //回显文件id集合
      newFileIds: [], // 上传文件ids
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        manageId: [{ required: true, message: '请选择', trigger: 'change' }],
        supervisorId: [{ required: true, message: '请选择', trigger: 'change' }],
        constructionId: [{ required: true, message: '请选择', trigger: 'change' }],
        inspectionTime: [{ required: true, message: '请选择', trigger: 'change' }],
        rectificationTime: [{ required: true, message: '请选择', trigger: 'change' }],
        inspectionUnit: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
        ],
        inspectionPersonnel: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
        ],
        contentIds: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      tableData: [
        {
          id: 2,
          date: '2016-05-02',
          name: '王小虎'
        }
      ],
      //检查内容列表
      contentList: [],
      //被检查管理单位下拉列表
      manageNameList: [],
      //被检查监理单位下拉列表
      supervisorNameList: [],
      //被检查施工单位下拉列表
      constructionNameList: [],
      details: {},
      editType: 1,
      isShowDetail: false,
      multipleSelection: [],
      inspectionTimeData: '',
      rectificationTimeData: '',
      titleName: '',
      detailData: {}
    };
  },
  methods: {
    //被检查管理单位方法
    changeManageName(id) {
      this.form.manageName = this.manageNameList.find(item => item.id === id).name;
    },
    //被检查监理单位方法
    changeSupervisorName(id) {
      this.form.supervisorName = this.supervisorNameList.find(item => item.id === id).name;
    },
    //被检查施工单位方法
    changeConstructionName(id) {
      this.form.constructionName = this.constructionNameList.find(item => item.id === id).name;
    },
    //表单检查内容
    getBaseList() {
      getBaseList({
        projectId: sessionStorage.getItem('projectId'),
        type: this.type
      }).then(res => {
        if (res.code === 200) {
          this.contentList = res.data;
        }
      });
    },
    getAllRelatedName() {
      getAllRelatedName({}).then(res => {
        if (res.code === 200) {
          this.manageNameList = res.data;
          this.supervisorNameList = res.data;
          this.constructionNameList = res.data;
        }
      });
    },
    init(data, titleName) {
      console.log(data);
      this.detailData = data.detail;
      this.titleName = titleName;
      //  type 新建/查看/编辑  1/2/3  detail
      this.editType = data.type;
      if (data.detail) {
        this.form = data.detail;
        if (data.detail.fileResults.length) {
          this.tableFileList = data.detail.fileResults;
          data.detail.fileResults.map(item => {
            item.operatTime = item.createTime;
            this.fileIdList.push(item.fileId);
          });
        }
      }
      console.log(this.form);
      // 上传附件回显

      console.log(this.form.contentIds);
      if (data.type === 2) {
        this.isShowDetail = true;
      }
    },
    handleBack(flag) {
      this.$emit('back', flag);
      this.reset();
    },
    reset() {
      this.isShowDetail = false;
      this.details = {};
      this.editType = 1;
      this.$refs.form.resetFields();
      this.fileIdList = [];
      this.tableFileList = [];

      this.form = this.$options.data().form;
    },
    inspectionTimeFunc(val) {
      this.inspectionTimeData = val;
      console.log(val);
    },
    rectificationTimeFunc(val) {
      this.rectificationTimeData = val;
    },

    // 保存
    handleSubmit() {
      console.log(this.form);
      console.log(this.detailData);
      if (this.inspectionTimeData !== '') {
        this.form.inspectionTime = this.inspectionTimeData + ' ' + '00:00:00';
      } else {
        this.form.inspectionTime = this.detailData.inspectionTime + ' ' + '00:00:00';
      }
      if (this.rectificationTimeData !== '') {
        this.form.rectificationTime = this.rectificationTimeData + ' ' + '23:59:59';
      } else {
        this.form.rectificationTime = this.detailData.rectificationTime + ' ' + '23:59:59';
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log(this.form);
          this.form.projectId = sessionStorage.getItem('projectId');
          this.form.type = this.type;
          addOrEdit(this.form).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功');
              //回到列表页刷新页面接口
              this.reset();
              this.handleBack(true);
            }
          });
        }
      });
    },
    //上传文件返回的文件id []
    getFileData(fileIds) {
      this.form.filesIds = fileIds;
    }
  },
  created() {
    this.getBaseList();
    this.getAllRelatedName();
  }
};
</script>

<style lang="less" scoped>
.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 16px;
}
.content-checkbox {
  max-height: 180px;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  text-align: left;
  padding: 0 20px;
  border-radius: 4px;
}
.detail-list {
  margin: 16px 40px;
  .list-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .export-download {
      color: #117bf7;
      border-bottom: 1px solid #117bf7;
    }
  }
}
.total {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0 80px;
  font-weight: bold;
  .total-item {
    width: 50%;
  }
}
.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  padding-top: 16px;
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  border-color: #409eff;
}
</style>
