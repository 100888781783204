<!--
 * @Author: your name
 * @Date: 2021-08-16 16:00:37
 * @LastEditTime: 2021-10-20 11:25:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\main\basicPage\quality\qualityInspection.vue
-->
<template>
  <div class="equip-container">
    <div v-show="indexShow">
      <div class="equip">{{ title }}</div>
      <div class="query-nav">
        <el-form ref="queryFormRef" :inline="true" :model="queryForm" label-width="80px">
          <el-form-item label="整改状态">
            <el-select v-model="queryForm.rectificationState" placeholder="请选择">
              <el-option
                v-for="item in deviceCategoryList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="巡检编号">
            <el-input v-model.trim="queryForm.inspectionNo" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="检查日期">
            <el-date-picker
              v-model="dateTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :default-value="Date.now() - 24 * 3600 * 1000"
              @change="dateTimeChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.isInspectionUnit"
              style="width:220px"
              placeholder="管理单位/监理单位/施工单位"
            ></el-input>
          </el-form-item>
          <el-button type="primary" style="margin-right: 6px;" @click="handleSearch">搜索</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form>
      </div>
      <div class="buts">
        <el-button type="primary" @click="addQuality(1)">新建</el-button>
        <div style="display: flex;">
          <div class="moban">
            <el-button type="text" @click="downloadClick">导入模板下载</el-button>
          </div>
          <importUpload @get-file="getFile" />
          <el-button class="ml-16" :disabled="!logIds.length" :loading="exportLoading" @click="exportQuality"
            >导出</el-button
          >
          <el-button type="danger" :disabled="!logIds.length" @click="handleDelete(logIds)">批量删除</el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="table"
          :data="qualityTabelList"
          v-loading="tableLoading"
          @selection-change="handleSelectionChange"
          style="width: 100%"
          size="medium"
          stripe
        >
          <el-table-column type="selection" width="60" align="center" fixed :resizable="false"></el-table-column>
          <el-table-column
            width="60"
            fixed
            type="index"
            label="序号"
            align="center"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            width="200"
            prop="inspectionNo"
            label="巡检编号"
            align="center"
            fixed
            :resizable="false"
          ></el-table-column>
          <el-table-column
            width="200"
            prop="projectName"
            label="项目名称"
            align="center"
            :resizable="false"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            width="200"
            prop="manageName"
            label="被检查管理单位"
            align="center"
            :resizable="false"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            width="200"
            prop="supervisorName"
            label="被检查监理单位"
            align="center"
            :resizable="false"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            width="200"
            prop="constructionName"
            label="被检查施工单位"
            align="center"
            :resizable="false"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            width="200"
            prop="inspectionTime"
            label="检查时间"
            align="center"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            width="200"
            prop="rectificationTime"
            label="整改时间"
            align="center"
            :resizable="false"
          ></el-table-column>
          <el-table-column width="200" prop="rectificationState" label="整改情况" align="center" :resizable="false">
            <template slot-scope="{ row }">
              <div class="stateBtn" @click="openStateDialog(row)">
                <div v-if="row.rectificationState === 1" style="background-color: #2FBF3F;">已整改</div>
                <div v-else-if="row.rectificationState === 2" style="background-color: #F79818;">待整改</div>
                <div v-else-if="row.rectificationState === 3" style="background-color: #979FA8;">取消</div>
                <div v-else style="background-color: #F56C6C;">超时</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="200"
            prop="inspectionPersonnel"
            label="参加检查人员"
            align="center"
            :resizable="false"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            width="200"
            prop="createTime"
            label="创建时间"
            align="center"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            width="200"
            fixed
            prop="orgName"
            label="操作"
            fixed="right"
            align="center"
            :resizable="false"
          >
            <template slot-scope="{ row }">
              <div class="operation">
                <span @click="addQuality(3, row)">修改</span>
                <span @click="addQuality(2, row)">查看</span>
                <span style="border-bottom: 1px solid #F56C6C;color: #F56C6C;" @click="handleDelete(row.id)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page">
        <el-pagination
          :total="total"
          :current-page="queryForm.pageNo"
          :page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 30, 50]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div v-show="!indexShow">
      <add-quality-form ref="addQualityForm" @back="backIndex" :type="type"></add-quality-form>
    </div>
    <state-dialog ref="stateDialogRef" @refresh="getSafetyList" />
  </div>
</template>
<script>
import addQualityForm from '@/views/main/basicPage/quality/addQualityForm';
import { exportMethods } from '@/utils/exportMethods.js';
import importUpload from '@/components/upload/ImportUpload';
import stateDialog from './stateDialog.vue';
import { getSafetyList, getDetail, postQualitySafetyDelete, importData } from 'api/main/qualityContent';
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: null
    }
  },
  components: { addQualityForm, importUpload, stateDialog },
  data() {
    return {
      total: 0,
      indexShow: true,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        type: null,
        inspectionNo: '',
        isInspectionUnit: '',
        rectificationState: '',
        inspectionStartTime: '',
        inspectionEndTime: '',
        projectId: ''
      },
      deviceCategoryList: [
        { id: 1, value: '已整改' },
        { id: 2, value: '待整改' },
        { id: 3, value: '取消' },
        { id: 4, value: '超时' }
      ],
      dateTime: '',
      qualityTabelList: [],
      tableLoading: false,
      logIds: [],
      exportLoading: false
    };
  },
  methods: {
    // 导出
    exportQuality() {
      this.exportLoading = true;
      exportMethods('/qualitySafety/export', { ids: this.logIds, type: this.type }, '质量巡检列表').finally(() => {
        this.exportLoading = false;
        this.logIds = [];
        this.$refs.table.clearSelection();
      });
    },
    // 导入
    getFile(file) {
      let fileData = new FormData();
      fileData.append('file', file);
      fileData.append('type', this.type.toString());
      fileData.append('projectId', sessionStorage.getItem('projectId'));
      importData(fileData).then(res => {
        if (res.data.fileInfo) {
          // 导入失败
          this.$message.error(res.data.data);
          const a = document.createElement('a');
          const e = document.createEvent('MouseEvents');
          e.initEvent('click', false, false);
          a.href = res.data.fileInfo.filePath;
          a.download = res.data.fileInfo.fileOriginName;
          a.dispatchEvent(e);
          return;
        }
        this.$message.success(res.data.data);
        this.getSafetyList();
      });
    },
    // 日期选择
    dateTimeChange(val) {
      if (val) {
        this.queryForm.inspectionStartTime = val[0] + ' ' + '00:00:00';
        this.queryForm.inspectionEndTime = val[1] + ' ' + '23:59:59';
      } else {
        this.queryForm.inspectionStartTime = '';
        this.queryForm.inspectionEndTime = '';
      }
    },
    // 搜索
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.getSafetyList();
    },
    // 重置
    handleReset() {
      this.$refs.queryFormRef.resetFields();
      this.queryForm = this.$options.data().queryForm;
      this.dateTime = '';
      this.getSafetyList();
    },
    // 分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNo = 1;
      this.getSafetyList();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      this.getSafetyList();
    },
    handleSelectionChange(logIds) {
      this.logIds = [];
      if (logIds.length) {
        logIds.map(item => {
          this.logIds.push(item.id);
        });
      } else {
      }
    },
    addQuality(type, detail) {
      // 新建/查看/编辑  1/2/3
      let data = {
        type,
        detail
      };
      if (type !== 1) {
        getDetail({ id: detail.id, type: this.type }).then(res => {
          if (res.code !== 200) return;
          data.detail = res.data;
          this.$refs.addQualityForm.init(data);
        });
      } else {
        if (this.type === 6) {
          this.$refs.addQualityForm.init(data, '新增质量巡检');
        } else if (this.type === 7) {
          this.$refs.addQualityForm.init(data, '新增安全巡检');
        }
        {
        }
      }

      this.indexShow = false;
    },
    backIndex(flag) {
      if (flag) {
        this.getSafetyList();
      }
      this.indexShow = true;
    },
    //获取列表
    getSafetyList() {
      this.tableLoading = true;
      this.queryForm.type = this.type;
      this.queryForm.projectId = sessionStorage.getItem('projectId');
      getSafetyList(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableLoading = false;
          const { total, records } = res.data;
          this.qualityTabelList = records;
          this.total = total;
        }
      });
    },
    // 点击删除
    handleDelete(data) {
      let ids;
      let message = '';
      if (typeof data === 'string') {
        ids = data.split(',');
        message = '删除成功！';
      } else if (Array.isArray(data)) {
        ids = data;
        message = '批量删除成功！';
      }

      this.$confirm('是否确认删除所选数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return postQualitySafetyDelete({ ids, type: this.type });
        })
        .then(res => {
          this.$message.success(message);
          this.handleReset();
        })
        .catch(err => {
          if (err) return; // axios 统一处理了错误提示
        });
    },
    openStateDialog(row) {
      console.log(row);
      let obj = {
        type: this.type,
        id: row.id
      };
      this.$refs.stateDialogRef.init(obj);
    },
    downloadClick() {
      let temName = '';
      if (this.type === 6) {
        temName = './质量巡检导入模板.xls';
      } else {
        temName = './安全巡检导入模板.xls';
      }
      //下载模板
      let link = document.createElement('a');
      // link.setAttribute('download', '项目批量导入模板');
      link.href = temName;
      link.click();
    }
  },
  created() {
    this.getSafetyList();
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-table .cell {
  white-space: pre;
}
.query-nav {
  border-bottom: 1px solid #dcdfe7;
  border-top: 1px solid #dcdfe7;
  padding-top: 24px;
}
.equip-container {
  .page {
    margin: 16px 0;
    float: right;
  }
}
.buts {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  .moban {
    width: 110px;
    border-bottom: 1px solid #117bf7;
    text-align: center;
    margin-right: 20px;
    line-height: 30px;
    cursor: pointer;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #117bf7;
  }
}
.table {
  margin-top: 12px;
}
.equip {
  font-size: 16px;
  padding: 0 16px 12px;
  font-weight: bold;
  color: #303133;
}
.operation {
  span {
    font-size: 14px;
    border-bottom: 1px solid #117bf7;
    color: #117bf7;
    margin: 0 8px;
    cursor: pointer;
  }
}
.stateBtn {
  div {
    color: #fff;
    width: 64px;
    height: 24px;
    cursor: pointer;
    // text-align: center;
    margin: 0 auto;
    border-radius: 2px;
  }
}
/deep/.el-table__empty-text {
  text-align: left;
}
</style>
